.contest-block {
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/media/contest/bg.png');
  background-size: 100% auto;
}
.contest-header {
  position: relative;
  width: 100%;
  height: 111.66vw;
  background-image: url('../assets/media/contest/header.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 51.388vw;
}
.contest-header-label {
  margin: 0 auto;
  width: 76.11vw;
  height: 16.759vw;
  background-image: url('../assets/media/contest/header-label.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.contest-time-label {
  margin-top: 4.35vw;
  color: #895AA1;
  font-size: 3.7vw;
  font-family: Raleway-ExtraBold;
  text-align: center;
  line-height: 4.629vw;
}
.contest-time-label > span {
  font-family: Raleway-SemiBold;
}
.contest-gifts {
  position: absolute;
  left: 7.037vw;
  bottom: -4.743vw;
  width: 84.722vw;
  height: 42.1296vw;
  background-image: url('../assets/media/contest/gifts.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.contest-stock {
  width: 100%;
  height: 149.166vw;
  background-image: url('../assets/media/contest/stock.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.contest-partners {
  position: relative;
  margin-top: -10vw;
  width: 100%;
  height: 110.462vw;
  background-image: url('../assets/media/contest/partners.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.contest-scan-button {
  position: absolute;
  left: 12.129vw;
  bottom: 14.35vw;
  width: 42.59vw;
  height: 7.315vw;
  background-color: #EE258B;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #FFFFFF;
  font-family: Raleway-SemiBold;
  font-size: 3vw;
  border-radius: 1.5vw;
}
.contest-timer {
  position: relative;
  margin-top: -3.5vw;
  width: 100%;
  height: 117vw;
  background-image: url('../assets/media/contest/timer.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.contest-merch {
  position: relative;
  margin-top: -6.5vw;
  width: 100%;
  height: 127.68vw;
  background-image: url('../assets/media/contest/merch.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.contest-merch-button {
  position: absolute;
  left: calc(50% - 21.287vw);
  bottom: 9.5vw;
  width: 42.574vw;
  height: 7.315vw;
  background-color: #C7EAA9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #61388D;
  font-family: Raleway-Bold;
  font-size: 3vw;
  border-radius: 1.5vw;
}
.contest-faq {
  position: relative;
  margin-top: -3vw;
  width: 100%;
  height: 108.24vw;
  background-image: url('../assets/media/contest/faq.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.contest-timer-block {
  position: absolute;
  top: 26.858vw;
  left: calc(50% - 57.63vw / 2);
  width: 57.63vw;
  height: 16.336vw;
  display: flex;
  justify-content: space-between;
}
.contest-timer-part {
  width: 15.963vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contest-timer-part-between {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.contest-timer-part-dots {
  width: 100%;
  height: 12.731vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EE258B;
  font-family: Raleway-ExtraBold;
  font-size: 10vw;
}
.contest-timer-board-block {
  width: 15.963vw;
  height: 12.731vw;
  display: flex;
  justify-content: space-between;
}
.contest-timer-board {
  height: 12.731vw;
  width: 7.676vw;
  background-color: #805E9C;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 1vw;
  font-size: 9.913vw;
  font-family: Raleway-SemiBold;
}
.contest-timer-label {
  width: 100%;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Raleway-Medium;
  font-size: 2.281vw;
}